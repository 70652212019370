import * as React from "react";

export const CookiePolicyPage = () => {
  return (
    <div>
      <h1>Polityka Plików Cookie</h1>

      <section>
        <h2>1. Czym są pliki cookie?</h2>
        <p>
          Pliki cookie to małe pliki tekstowe przechowywane na urządzeniu
          użytkownika podczas odwiedzania stron internetowych. Umożliwiają one
          prawidłowe działanie witryny, a także pomagają nam w analizie ruchu
          oraz personalizacji treści.
        </p>
      </section>

      <section>
        <h2>2. Rodzaje plików cookie, które wykorzystujemy</h2>
        <ul>
          <li>
            <strong>Pliki cookie niezbędne:</strong> Są konieczne do
            prawidłowego funkcjonowania naszej aplikacji i umożliwiają
            korzystanie z podstawowych funkcji, takich jak logowanie do konta
            czy realizacja zamówienia.
          </li>
          <li>
            <strong>Pliki cookie analityczne:</strong> Pozwalają nam analizować
            sposób, w jaki użytkownicy korzystają z aplikacji, co pomaga nam w
            jej optymalizacji i poprawie jakości usług.
          </li>
          <li>
            <strong>Pliki cookie funkcjonalne:</strong> Umożliwiają
            zapamiętywanie ustawień i preferencji użytkownika, takich jak język
            czy region, co zwiększa wygodę korzystania z aplikacji.
          </li>
          <li>
            <strong>Pliki cookie marketingowe:</strong> Używane są do
            wyświetlania spersonalizowanych treści i reklam dostosowanych do
            zainteresowań użytkownika.
          </li>
        </ul>
      </section>

      <section>
        <h2>3. Zarządzanie plikami cookie</h2>
        <p>
          Użytkownik ma możliwość zarządzania plikami cookie poprzez ustawienia
          swojej przeglądarki internetowej. Można zablokować lub usunąć pliki
          cookie, jednakże może to wpłynąć na funkcjonalność naszej aplikacji.
          Więcej informacji na temat zarządzania plikami cookie można znaleźć w
          pomocy swojej przeglądarki:
        </p>
        <ul>
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=pl"
              target="_blank"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek"
              target="_blank"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies"
              target="_blank"
            >
              Microsoft Edge
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"
              target="_blank"
            >
              Safari
            </a>
          </li>
        </ul>
      </section>

      <section>
        <h2>4. Cele wykorzystania plików cookie</h2>
        <p>Pliki cookie wykorzystujemy w celu:</p>
        <ul>
          <li>Umożliwienia i ułatwienia korzystania z naszej aplikacji,</li>
          <li>
            Analizy ruchu na stronie i identyfikacji problemów technicznych,
          </li>
          <li>
            Personalizacji treści i reklam w oparciu o zainteresowania
            użytkownika,
          </li>
          <li>Zwiększenia bezpieczeństwa naszej aplikacji.</li>
        </ul>
      </section>

      <section>
        <h2>5. Okres przechowywania plików cookie</h2>
        <p>
          Okres przechowywania plików cookie zależy od ich rodzaju. Pliki cookie
          sesyjne są usuwane po zamknięciu przeglądarki, natomiast pliki cookie
          trwałe pozostają na urządzeniu użytkownika przez określony czas lub do
          momentu ich usunięcia.
        </p>
      </section>

      <section>
        <h2>6. Zmiany w polityce plików cookie</h2>
        <p>
          Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej polityce
          plików cookie. O wszelkich zmianach użytkownicy będą informowani
          poprzez odpowiednią notyfikację w aplikacji.
        </p>
      </section>

      <section>
        <h2>7. Inne ważne informacje</h2>
        <p>
          Soft Bind Sebastian Sobociński nie ponosi odpowiedzialności za
          jakiekolwiek wykorzystanie plików cookie przez podmioty zewnętrzne
          poza usługami oferowanymi przez kanały i media należące do Soft Bind
          Sebastian Sobociński.
        </p>
      </section>

      <section>
        <h2>7. Kontakt</h2>
        <p>
          W przypadku pytań dotyczących polityki plików cookie, prosimy o
          kontakt na adres:{" "}
          <strong>
            <a href="mailto:kontakt@zozia.app">kontakt@zozia.app</a>
          </strong>
          .
        </p>
      </section>
      <section>
        <p>Wersja obowiązująca od dnia 03/10/2024</p>
      </section>
    </div>
  );
};

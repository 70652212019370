import * as React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { CookiePolicyPage } from "./pages/CookiePolicy";
import { LegalTermsPage } from "./pages/LegalTerms";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicy";
import { Root } from "./pages/Root";
import { Zozia } from "./pages/Zozia";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Zozia />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "/cookie-policy",
        element: <CookiePolicyPage />,
      },
      {
        path: "/legal-terms",
        element: <LegalTermsPage />,
      },
    ],
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};

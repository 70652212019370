import { MantineProvider } from "@mantine/core";
import * as React from "react";
import { Link, Outlet } from "react-router-dom";

import downloadIosApp from "../../public/download-apple.png";
import downloadAndroidApp from "../../public/download-google.png";

export const Root = () => {
  return (
    <MantineProvider>
      <div className="container mx-auto h-full">
        <header className="flex justify-between items-center max-w-screen-xl mx-auto mt-8">
          <Link to="/" className="flex items-center gap-2">
            <img
              src={require("../../public/apple-touch-icon.png")}
              alt="logo"
              className="h-12"
            />
          </Link>
          <div className="flex flex-wrap justify-center lg:justify-end items-center -mr-12">
            <a
              className="mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700"
              href="mailto:kontakt@zozia.app"
            >
              Zostań Partnerem
            </a>
          </div>
        </header>
        <main>
          <Outlet />
        </main>
      </div>
      <footer>
        <section className="grid grid-cols-4 gap-4 justify-between mb-[15rem] container mx-auto">
          <div className="text-center flex flex-col">
            <h3>Współpraca</h3>
            <ul className="list-none flex flex-col gap-7 p-0">
              <li>Zozia dla partnerów</li>
            </ul>
          </div>
          <div className="text-center flex flex-col">
            <h3>Przydatne linki</h3>
            <ul className="list-none flex flex-col gap-7 p-0">
              <li>O nas</li>
              <li>FAQ</li>
            </ul>
          </div>
          <div className="text-center flex flex-col">
            <h3>Obserwuj nas</h3>
            <ul className="list-none flex flex-col gap-7 p-0">
              <li>
                <a href="https://facebook.com/zoziaapp" target="_blank">
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://x.com/zoziaapp" target="_blank">
                  x.com
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/zozia.app" target="_blank">
                  Instagram
                </a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-center gap-4">
            <img style={{ width: 134, height: 40 }} src={downloadAndroidApp} />
            <img style={{ width: 134, height: 40 }} src={downloadIosApp} />
            <ul className="list-none flex flex-col gap-7 p-0 text-center">
              <li>
                <Link reloadDocument to="legal-terms">
                  Warunki i postanowienia
                </Link>
              </li>
              <li>
                <Link reloadDocument to="privacy-policy">
                  Polityka prywatności
                </Link>
              </li>
              <li>
                <Link reloadDocument to="cookie-policy">
                  Polityka plików cookie
                </Link>
              </li>
            </ul>
          </div>
        </section>
      </footer>
    </MantineProvider>
  );
};

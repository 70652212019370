import { Loader } from "@mantine/core";
import * as React from "react";

import { DeviceFrame } from "./DeviceFrame";

export const Zozia = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  return (
    <div className="mx-auto w-[460px]">
      <DeviceFrame>
        {isLoading ? <Loader /> : null}
        <iframe
          onLoad={() => {
            setIsLoading(false);
          }}
          allow="geolocation 'self' https://app.zozia.app"
          src="https://app.zozia.app"
          title="Zozia"
          className="w-[550px] h-full border-none rounded-b-[2.5rem] user-select-none"
          style={{
            visibility: isLoading ? "hidden" : "visible",
          }}
        />
      </DeviceFrame>
    </div>
  );
};

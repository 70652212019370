import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    position: "relative",
    width: "483px",
    height: "759px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    overflow: "hidden",
  },
  frame: {
    opacity: 0.644,
    fill: "rgba(151, 187, 215, 1)",
    position: "absolute",
    overflow: "visible",
    width: "339px",
    height: "698px",
    left: "65px",
    top: "31px",
  },
  bezel: {
    fill: "rgba(0, 0, 0, 1)",
    position: "absolute",
    overflow: "visible",
    width: "329px",
    height: "688px",
    left: "70px",
    top: "36px",
  },
  screen: {
    fill: "#f1f1f1",
    position: "absolute",
    overflow: "visible",
    width: "306px",
    height: "664px",
    left: "81px",
    top: "49px",
  },
  backgroundImg: {
    borderRadius: "30px",
    position: "absolute",
    width: "307px",
    height: "664px",
    left: "81px",
    top: "49px",
    overflow: "visible",
  },
  notch: {
    fill: "rgba(0, 0, 0, 1)",
    position: "absolute",
    overflow: "visible",
    width: "127px",
    height: "27px",
    left: "170px",
    top: "48px",
  },
  camera: {
    position: "absolute",
    width: "12px",
    height: "12px",
    left: "182px",
    top: "51px",
    overflow: "visible",
  },
  outerCamera: {
    fill: "rgba(13, 13, 13, 1)",
    position: "absolute",
    overflow: "visible",
    width: "12px",
    height: "12px",
    left: "0px",
    top: "0px",
  },
  innerCamera: {
    fill: "rgba(0, 55, 95, 1)",
    position: "absolute",
    overflow: "visible",
    width: "4px",
    height: "4px",
    left: "4px",
    top: "4px",
  },
  speaker: {
    fill: "rgba(49, 49, 49, 1)",
    position: "absolute",
    overflow: "visible",
    width: "58px",
    height: "7px",
    left: "205px",
    top: "37px",
  },
  powerButton: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "3px",
    height: "83px",
    left: "404px",
    top: "240px",
  },
  volumeControls: {
    position: "absolute",
    width: "3px",
    height: "173px",
    left: "62px",
    top: "170px",
    overflow: "visible",
  },
  volumeDown: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "3px",
    height: "55px",
    left: "0px",
    top: "118px",
  },
  volumeUp: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "3px",
    height: "55px",
    left: "0px",
    top: "52px",
  },
  silentToggle: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "3px",
    height: "29px",
    left: "0px",
    top: "0px",
  },
  antennaBands: {
    position: "absolute",
    width: "339px",
    height: "698px",
    left: "65px",
    top: "31px",
    overflow: "visible",
  },
  tRBand: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "4px",
    height: "5px",
    left: "266px",
    top: "0px",
  },
  tLBand: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "4px",
    height: "5px",
    left: "67px",
    top: "0px",
  },
  lTBand: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "5px",
    height: "4px",
    left: "0px",
    top: "72px",
  },
  lBBand: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "5px",
    height: "4px",
    left: "0px",
    top: "624px",
  },
  bLband: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "4px",
    height: "5px",
    left: "68px",
    top: "693px",
  },
  bRBand: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "4px",
    height: "5px",
    left: "267px",
    top: "693px",
  },
  rBBand: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "5px",
    height: "4px",
    left: "334px",
    top: "624px",
  },
  rTBand: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "5px",
    height: "3px",
    left: "334px",
    top: "72px",
  },
  children: {
    position: "absolute",
    overflow: "visible",
    width: "306px",
    height: "634px",
    left: "81px",
    top: "78px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    zIndex: 1,
  },
});

import * as React from "react";

export const PrivacyPolicyPage = () => {
  return (
    <div className="mx-auto">
      <h1>Polityka Prywatności</h1>

      <section>
        <h2>1. Informacje ogólne</h2>
        <p>
          Niniejsza polityka prywatności określa zasady przetwarzania danych
          osobowych w ramach aplikacji marketplace, prowadzonej przez{" "}
          <strong>Soft Bind Sebastian Sobociński</strong> z siedzibą w{" "}
          <strong>ul. Frycza Modrzewskiego 9, 95-060 Brzeziny</strong>.
          Dokładamy wszelkich starań, aby chronić prywatność użytkowników oraz
          ich dane osobowe zgodnie z obowiązującymi przepisami prawnymi.
        </p>
      </section>

      <section>
        <h2>2. Administrator danych</h2>
        <p>
          Administratorem danych osobowych jest{" "}
          <strong>
            Soft Bind Sebastian Sobociński ul. Frycza Modrzewskiego 9, 95-060
            Brzeziny, <a href="mailto:kontakt@zozia.app">kontakt@zozia.app</a>{" "}
            oraz <a href="tel:+48881799401">+48 881 799 401</a>
          </strong>
          .
        </p>
      </section>

      <section>
        <h2>3. Rodzaje danych osobowych</h2>
        <p>Gromadzimy następujące dane osobowe:</p>
        <ul>
          <li>Dane identyfikacyjne (np. imię, nazwisko, nazwa użytkownika),</li>
          <li>Dane kontaktowe (np. adres e-mail, numer telefonu),</li>
          <li>Dane transakcyjne (np. historia zakupów, metody płatności),</li>
          <li>
            Informacje o urządzeniu (np. adres IP, dane techniczne urządzenia).
          </li>
        </ul>
      </section>

      <section>
        <h2>4. Cele przetwarzania danych</h2>
        <p>Przetwarzamy dane osobowe w następujących celach:</p>
        <ul>
          <li>Realizacja zamówień i transakcji,</li>
          <li>Obsługa konta użytkownika,</li>
          <li>
            Przesyłanie powiadomień i informacji marketingowych (za zgodą
            użytkownika),
          </li>
          <li>Analiza i udoskonalanie naszych usług,</li>
          <li>Spełnienie wymogów prawnych i regulacyjnych.</li>
        </ul>
      </section>

      <section>
        <h2>5. Podstawa prawna przetwarzania danych</h2>
        <p>Przetwarzanie danych osobowych odbywa się na podstawie:</p>
        <ul>
          <li>Zgody użytkownika (art. 6 ust. 1 lit. a RODO),</li>
          <li>Realizacji umowy (art. 6 ust. 1 lit. b RODO),</li>
          <li>
            Obowiązku prawnego ciążącego na administratorze (art. 6 ust. 1 lit.
            c RODO),
          </li>
          <li>
            Prawnie uzasadnionego interesu administratora (art. 6 ust. 1 lit. f
            RODO).
          </li>
        </ul>
      </section>

      <section>
        <h2>6. Udostępnianie danych osobowych</h2>
        <p>Dane osobowe użytkowników mogą być przekazywane:</p>
        <ul>
          <li>
            Partnerom biznesowym, w celu realizacji transakcji (np. operatorom
            płatności, dostawcom),
          </li>
          <li>
            Podmiotom przetwarzającym dane na zlecenie administratora (np.
            usługodawcom IT),
          </li>
          <li>
            Organom uprawnionym do ich otrzymania na podstawie przepisów prawa.
          </li>
        </ul>
      </section>

      <section>
        <h2>7. Okres przechowywania danych</h2>
        <p>
          Dane osobowe przechowywane są tak długo, jak jest to konieczne do
          realizacji celów, w których zostały zgromadzone lub tak długo, jak
          jest to wymagane przepisami prawa. Po tym okresie dane są usuwane lub
          anonimizowane.
        </p>
      </section>

      <section>
        <h2>8. Prawa użytkownika</h2>
        <p>Użytkownik ma prawo do:</p>
        <ul>
          <li>Dostępu do swoich danych,</li>
          <li>Sprostowania danych,</li>
          <li>Usunięcia danych („prawo do bycia zapomnianym”),</li>
          <li>Ograniczenia przetwarzania,</li>
          <li>Przenoszenia danych,</li>
          <li>Sprzeciwu wobec przetwarzania,</li>
          <li>
            Cofnięcia zgody na przetwarzanie danych w dowolnym momencie (jeżeli
            przetwarzanie odbywa się na podstawie zgody).
          </li>
        </ul>
        <p>
          Aby skorzystać ze swoich praw, użytkownik może skontaktować się z nami
          na adres:{" "}
          <strong>
            <a href="mailto:kontakt@zozia.app">kontakt@zozia.app</a>
          </strong>
          .
        </p>
      </section>

      <section>
        <h2>9. Pliki cookies i technologie śledzące</h2>
        <p>
          W aplikacji mogą być stosowane pliki cookies oraz inne technologie
          śledzące w celu personalizacji treści, analizy ruchu oraz
          optymalizacji usług. Użytkownik ma możliwość zarządzania preferencjami
          dotyczącymi plików cookies w ustawieniach aplikacji lub przeglądarki.
        </p>
      </section>

      <section>
        <h2>10. Bezpieczeństwo danych</h2>
        <p>
          Dokładamy wszelkich starań, aby chronić dane osobowe użytkowników
          przed nieuprawnionym dostępem, utratą, zniszczeniem lub ujawnieniem,
          stosując odpowiednie środki techniczne i organizacyjne.
        </p>
      </section>

      <section>
        <h2>11. Zmiany polityki prywatności</h2>
        <p>
          Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej polityce
          prywatności. O wszelkich zmianach użytkownicy będą informowani poprzez
          odpowiednią notyfikację w aplikacji lub drogą mailową.
        </p>
      </section>

      <section>
        <h2>12. Kontakt</h2>
        <p>
          W przypadku pytań dotyczących polityki prywatności lub przetwarzania
          danych osobowych, prosimy o kontakt na adres:{" "}
          <strong>
            <a href="mailto:kontakt@zozia.app">kontakt@zozia.app</a>
          </strong>
          .
        </p>
      </section>
      <section>
        <p>Wersja obowiązująca od dnia 03/10/2024</p>
      </section>
    </div>
  );
};

import * as React from "react";

export const LegalTermsPage = () => {
  return (
    <div className="mx-auto">
      <h1>Regulamin Aplikacji Zozia</h1>
      <section>
        <h2>1. Informacje ogólne</h2>
        <p>
          Niniejszy regulamin (dalej „Regulamin”) określa zasady korzystania z
          aplikacji Zozia prowadzonej przez{" "}
          <strong>Soft Bind Sebastian Sobociński</strong> z siedzibą w{" "}
          <strong>ul. Frycza Modrzewskiego 9, 95-060 Brzeziny</strong>.
          Korzystając z naszej aplikacji, użytkownik akceptuje postanowienia
          niniejszego Regulaminu.
        </p>
      </section>
      <section>
        <h2>2. Definicje</h2>
        <p>
          Na potrzeby niniejszego Regulaminu przyjmuje się następujące
          definicje:
        </p>
        <ul>
          <li>
            <strong>Użytkownik</strong> – osoba fizyczna lub prawna korzystająca
            z aplikacji marketplace.
          </li>
          <li>
            <strong>Usługodawca</strong> – podmiot oferujący swoje produkty lub
            usługi za pośrednictwem aplikacji.
          </li>
          <li>
            <strong>Aplikacja</strong> – platforma internetowa umożliwiająca
            łączenie Użytkowników z Usługodawcami w celu realizacji transakcji.
          </li>
        </ul>
      </section>
      <section>
        <h2>3. Zakres świadczonych usług</h2>
        <p>
          Aplikacja umożliwia Użytkownikom składanie zamówień na produkty i
          usługi oferowane przez Usługodawców. Usługodawca ponosi pełną
          odpowiedzialność za realizację zamówienia, a Aplikacja pełni rolę
          pośrednika między Użytkownikiem a Usługodawcą.
        </p>
      </section>
      <section>
        <h2>4. Rejestracja i Konto Użytkownika</h2>
        <ul>
          <li>
            Rejestracja w Aplikacji jest dobrowolna i wymaga podania danych
            osobowych Użytkownika.
          </li>
          <li>
            Użytkownik zobowiązuje się do podania prawdziwych i aktualnych
            danych podczas rejestracji.
          </li>
          <li>
            Użytkownik jest odpowiedzialny za bezpieczeństwo swojego konta oraz
            hasła.
          </li>
        </ul>
      </section>
      <section>
        <h2>5. Zasady składania zamówień</h2>
        <p>
          Użytkownik może składać zamówienia na produkty lub usługi za
          pośrednictwem Aplikacji. Złożenie zamówienia stanowi ofertę zawarcia
          umowy sprzedaży z Usługodawcą. W momencie potwierdzenia zamówienia
          przez Usługodawcę, umowa między Użytkownikiem a Usługodawcą zostaje
          zawarta.
        </p>
      </section>
      <section>
        <h2>6. Ceny i Płatności</h2>
        <ul>
          <li>
            Ceny produktów i usług widoczne w Aplikacji są cenami brutto i
            zawierają podatek VAT.
          </li>
          <li>
            Płatności za zamówienia można dokonywać za pomocą dostępnych metod
            płatności w Aplikacji.
          </li>
          <li>
            Po złożeniu zamówienia Użytkownik zobowiązany jest do jego opłacenia
            zgodnie z wybraną metodą płatności.
          </li>
        </ul>
      </section>
      <section>
        <h2>7. Anulowanie i Zwroty</h2>
        <ul>
          <li>
            Użytkownik ma prawo do anulowania zamówienia w przypadkach
            określonych w Aplikacji, jeśli zamówienie nie zostało jeszcze
            przekazane do realizacji.
          </li>
          <li>
            W przypadku wadliwego produktu lub usługi Użytkownik może zgłosić
            reklamację, kontaktując się z Usługodawcą lub przez formularz
            dostępny w Aplikacji.
          </li>
          <li>
            Zasady zwrotów i reklamacji są zgodne z obowiązującymi przepisami
            prawa.
          </li>
        </ul>
      </section>
      <section>
        <h2>8. Odpowiedzialność</h2>
        <ul>
          <li>
            Aplikacja nie ponosi odpowiedzialności za jakość ani terminowość
            usług świadczonych przez Usługodawców.
          </li>
          <li>
            Usługodawca ponosi pełną odpowiedzialność za zgodność produktów lub
            usług z opisem oraz za realizację zamówień.
          </li>
          <li>
            Aplikacja nie odpowiada za szkody wynikające z nieprawidłowego
            korzystania przez Użytkownika z Aplikacji.
          </li>
        </ul>
      </section>
      <section>
        <h2>9. Prawa własności intelektualnej</h2>
        <p>
          Wszelkie prawa autorskie oraz prawa własności intelektualnej do
          Aplikacji, jej treści, kodu i grafiki przysługują{" "}
          <strong>Soft Bind Sebastian Sobociński</strong>. Użytkownik
          zobowiązuje się do niekopiowania, niemodyfikowania oraz
          nierozpowszechniania żadnych elementów Aplikacji bez zgody{" "}
          <strong>Soft Bind Sebastian Sobociński</strong>.
        </p>
      </section>
      <section>
        <h2>10. Ochrona danych osobowych</h2>
        <p>
          Dane osobowe Użytkowników przetwarzane są zgodnie z Polityką
          Prywatności Aplikacji. Więcej informacji można znaleźć w Polityce
          Prywatności dostępnej w Aplikacji lub{" "}
          <a href="/privacy-policy">tutaj</a>.
        </p>
      </section>
      <section>
        <h2>11. Zmiany Regulaminu</h2>
        <p>
          Zastrzegamy sobie prawo do wprowadzania zmian w niniejszym
          Regulaminie. Wszelkie zmiany będą publikowane w Aplikacji i wchodzą w
          życie z dniem ich opublikowania. Korzystanie z Aplikacji po
          wprowadzeniu zmian jest równoznaczne z ich akceptacją przez
          Użytkownika.
        </p>
      </section>
      <section>
        <h2>12. Kontakt</h2>
        <p>
          W przypadku pytań lub wątpliwości dotyczących niniejszego Regulaminu,
          prosimy o kontakt na adres e-mail:{" "}
          <strong>
            <a href="mailto:kontakt@zozia.app">kontakt@zozia.app</a>
          </strong>
          .
        </p>
      </section>
      <section>
        <h2>13. Postanowienia końcowe</h2>
        <ul>
          <li>Niniejszy Regulamin podlega przepisom prawa polskiego.</li>
          <li>
            W sprawach nieuregulowanych w Regulaminie mają zastosowanie przepisy
            kodeksu cywilnego oraz inne obowiązujące przepisy prawa.
          </li>
          <li>
            Jeśli którekolwiek z postanowień Regulaminu okaże się nieważne lub
            niewykonalne, pozostałe postanowienia pozostają w mocy.
          </li>
        </ul>
      </section>
      <section>
        <p>Wersja obowiązująca od dnia 03/10/2024</p>
      </section>
    </div>
  );
};
